const keys = {
  // For localStorage
  AUTH_TOKEN: 'AUTH_TOKEN', // JSON Web Token
  REDIRECT: 'REDIRECT',
  REDIRECT_AFTER_LOGIN: 'REDIRECT_AFTER_LOGIN',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  SPLASH_LOGO: 'SPLASH_LOGO',
  OTP_METHOD_LABEL: 'otp_method_label',
  DEFAULT_HELP_TAB: 'DEFAULT_HELP_TAB',
  // Config
  IS_DARK: 'isDark',
  IS_FLUID: 'isFluid',
  NAVBAR_POSITION: 'navbarPosition',
  SHOW_BURGER_MENU: 'showBurgerMenu',
  NAVBAR_STYLE: 'navbarStyle',
  PAGE_BOTTOM_FILE: 'PAGE_BOTTOM_FILE',
  PAGE_TOP_FILE: 'PAGE_TOP_FILE',
  // Others
  DATA_VIEW_LIST_ACTION: 'viewlist',
  DATA_VIEW_ACTION: 'view',
  DATA_ADD_ACTION: 'add',
  DATA_EDIT_ACTION: 'edit',
  APP_MAIN_BODY_CONTENT_ID: 'app-main-body-content',
  EDIT_PERMISSIONS_KEY: 'edit',
  DELETE_PERMISSIONS_KEY: 'delete',
  ADD_PERMISSIONS_KEY: 'add',
  PROFILE_IMAGE_FIELD_KEY: 'imageFILE',
  FA_FIELD_TYPE: 'fontawesome-icon-list',
  SELECT_TYPES: ['lookup_select', 'select'],
  ADD_FORM_SUBMIT_BUTTON: 'ADD_FORM_SUBMIT_BUTTON',
  LOAD_TEMPLATE: 'LOAD_TEMPLATE',
  DATA_PROFILE_ACTION: 'profile',
  DATE_FIELD_KEY: 'date',
  DATE_TIME_FIELD_KEY: 'datetime'
};

export default keys;
