import React from 'react';
import Loading from '.';

/* props can be -> color, msg, msgColor, opacity */
function SplashScreenLoading(props) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100vh',
        width: '100vw',
        scroll: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999999
      }}
    >
      <Loading {...props} style={{ marginTop: 0 }} msgColor="inherit" />
    </div>
  );
}

export default SplashScreenLoading;
