import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  viewingUserData: {},
  currentModuleSchema: {},
  currentModuleSchemaLoading: false,
  fieldPluginTable: null,
  fieldPluginTableLoading: true,
  editFormSaved: true,
  lookupFieldAdditionalOptions: {}, // { fieldKey: [{Option1}, {Option2},...], ...}
  statusError: null,
  moduleCurrentProgress: null
};

/* Store overall app current(In view) data */
const currentDataSlice = createSlice({
  name: 'currentData',
  initialState,
  reducers: {
    setCurrentData(currentData, { payload }) {
      return { ...currentData, ...payload };
    },
    dispatchStatusError(state, { payload }) {
      state.statusError = payload;
    },
    setModuleCurrentProgress(state, { payload }) {
      state.moduleCurrentProgress = payload;
    }
  }
});

export const { setCurrentData, dispatchStatusError, setModuleCurrentProgress } = currentDataSlice.actions;

export default currentDataSlice.reducer;
