// const devEnv = [
//   'localhost',
//   'netlify.app',
//   'digitalocean.app',
//   'blueboxtestsys.com',
//   'ngrok.io'
// ];
// const HOST = window.location.origin;
// export const API_BASE_URL = devEnv.some(x =>
//   HOST.trim().toLocaleLowerCase().includes(x.trim().toLocaleLowerCase())
// )
//   ? `https://schuitema.blueboxonline.com`
//   : HOST;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_VERSION = `v1`;
export const API_ENDPOINT_PREFIX = `${API_BASE_URL}/api/${API_VERSION}`;

export default {
  settings: `${API_ENDPOINT_PREFIX}/app/settings`,
  login: `${API_ENDPOINT_PREFIX}/users/login`,
  otp: `${API_ENDPOINT_PREFIX}/users/otp`,
  forgotPassword: `${API_ENDPOINT_PREFIX}/users/forgot-password`,
  resetPassword: `${API_ENDPOINT_PREFIX}/users/reset-password`,
  logout: `${API_ENDPOINT_PREFIX}/users/logout`,
  userProfile: `${API_ENDPOINT_PREFIX}/users/profile`,
  authTokenEndpoint: `${API_ENDPOINT_PREFIX}/oauth/token`,
  authRevokeEndpoint: `${API_ENDPOINT_PREFIX}/oauth/revoke`,
  binaryFileUploadEndpoint: `${API_BASE_URL}/?class=bb_sc_api_files&method=upload_binary_file`,
  getEndpointWithSuffix: suffix => `${API_ENDPOINT_PREFIX}${suffix}`,
  getPageSchemaEndpoint: routeKey => `${API_ENDPOINT_PREFIX}/pages/${routeKey}`,
  getDataModuleSchemaEndpoint: (mn, action, id) =>
    `${API_ENDPOINT_PREFIX}/schemas/${mn}/${action}${id ? `?id=${id}` : ''}`,
  getModuleDataEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}`,
  getDropTableEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/table`,
  getDropColumnEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/columns`,
  getDataAddEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}`,
  getDataItemViewEndpoint: (mn, id) => `${API_ENDPOINT_PREFIX}/module/${mn}/${id}`,
  getDataItemDublicateEndpoint: (mn, id) => `${API_ENDPOINT_PREFIX}/module/${mn}/${id}/duplicate`,
  getDataItemEditEndpoint: (mn, id) => `${API_ENDPOINT_PREFIX}/module/${mn}/${id}`,
  getDataItemDeleteEndpoint: (mn, id) => `${API_ENDPOINT_PREFIX}/module/${mn}/${id}`,
  getModuleSettingsEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/settings`,
  getModuleSettingUpdateEndpoint: (mn, setting) => `${API_ENDPOINT_PREFIX}/module/${mn}/settings/${setting}`,
  getDefaultDataEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/table/default-data`,
  getNotificationClickEndpoint: id => `${API_ENDPOINT_PREFIX}/users/notifications/${id}/read`,
  getMoreNotificationEndpoint: (offset = 5) => `${API_ENDPOINT_PREFIX}/users/notifications?offset=${offset}`,
  fontAwesomeIcons: `${API_ENDPOINT_PREFIX}/fontawesome-icon-list`,
  getLookupEndpoint: suffix => `${API_ENDPOINT_PREFIX}${suffix}`,
  getBatchEndpointForSort: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/batch`,
  getDataList: mn => `${API_ENDPOINT_PREFIX}/schuitema/${mn}`, // Get List of New model, e.g. users
  getDataItem: (mn, id) => `${API_ENDPOINT_PREFIX}/schuitema/${mn}/${id}`,
  getDataItemTabViewEndpoint: (suffix, id) => `${API_ENDPOINT_PREFIX}/${suffix.replace(':id', id)}`,
  getConsultantEndpoint: mn => `${API_ENDPOINT_PREFIX}/consultant/${mn}`,
  getConsultantItemEndpoint: (mn, id) => `${API_ENDPOINT_PREFIX}/consultant/${mn}/${id}`,
  getClientEndpoint: mn => `${API_ENDPOINT_PREFIX}/client/${mn}`,
  getClientItemEndpoint: (mn, id) => `${API_ENDPOINT_PREFIX}/client/${mn}/${id}`,
  getAdminDataEndpoint: mn => `${API_ENDPOINT_PREFIX}/admin/${mn}`,
  getAdminDataByIdEndpoint: (mn, id) => `${API_ENDPOINT_PREFIX}/admin/${mn}/${id}`
};
