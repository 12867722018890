import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: []
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setQuestions(client, { payload }) {
      return { ...client, questions: [...payload] };
    }
  }
});

export const { setQuestions } = clientSlice.actions;

export default clientSlice.reducer;
