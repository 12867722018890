import { notification } from 'antd';
import status from 'statuses';

import keys from './keys';

/* Detail Error Log of AJAX req */
const handleError = (error, notify = false, errMessage = '') => {
  console.log('===============Error Info==================');
  let title = 'ERROR';
  let msg = errMessage || 'Something went wrong. Check your internet connection and try again!';
  let statusCode = null;
  if (error.response) {
    statusCode = error.response.status;
    console.log('Error Data: ', error.response.data);
    console.log('Error Status Code: ', statusCode);
    console.log('Error Headers: ', error.response.headers);
    // Server responded with a status code that falls out of the range of 2xx

    if (statusCode === 401) {
      // Remove token from local storage
      localStorage.removeItem(keys.ACCESS_TOKEN);
      // Kick to login page
      setTimeout(() => window.location.reload(), 2000);
      title = 'Unauthorized';
      msg = 'Please login again.';
      return { msg, statusCode };
    }
    if (error.response.data) {
      const { error: errorMsg, error_description, message } = error.response.data;
      if (errorMsg) {
        msg = errorMsg;
        if (msg === 'invalid_token' && statusCode === 401) {
          // Remove token from local storage
          localStorage.removeItem(keys.ACCESS_TOKEN);
          // Kick to login page
          setTimeout(() => window.location.reload(), 2000);
          title = 'Account archived';
          msg = 'Please contact support.';
        } else if (msg === 'invalid_grant') {
          msg =
            'Refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.';
          // Remove token from local storage
          localStorage.removeItem(keys.ACCESS_TOKEN);
        } else if (msg === 'untrusted_source') {
          msg = error_description;
        }
      } else if (message) {
        msg = message;
      } else {
        msg = `${statusCode}: ${status(statusCode)}`;
      }
    } else {
      console.log('Error Response:', error.response);
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('Error: no response was received\n------------------\n', error.request);
    msg = error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error: setting up the request\n-------------------\n', error.message);
    msg = JSON.stringify(error);
  }
  // console.log('-----Error-------:\n-----------------\n', JSON.stringify(error));
  // console.log('Error Config:\n-----------------\n', error.config);
  // console.log('==============End Error Info=============');
  const finalMsg = msg.toString().replace('ERROR Error:', '').replace('Error:', '');
  //const finalMsg = JSON.stringify(error);
  if (finalMsg && notify) {
    notification.error({
      message: title,
      description: finalMsg,
      duration: 6
      // closeIcon: <CloseCircleOutlined />,
    });
  }
  return { finalMsg, statusCode };
};

export default handleError;
