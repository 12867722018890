import Axios from 'axios';
import { Provider } from 'react-redux';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';

import './assets/less/antd.less';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import AppBody from 'AppBody';
import keys from 'utils/keys';
import store from './redux/store';
import endpoint from 'utils/endpoint';
import handleError from 'utils/handleError';
import { /*getQueryVariable,*/ sleep } from 'helpers/utils';
import { setCurrentUser } from 'redux/slices/authSlice';
import { setSettings } from 'redux/slices/settingsSlice';
import LoadingCenter from 'components/loading/LoadingCenter';
import SplashScreenLoading from 'components/loading/SplashScreenLoading';
import AppContext from 'context/Context';

const App = () => {
  const _isMounted = useRef(false);
  const [checking, setChecking] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { setConfig } = useContext(AppContext);

  const resetConfigWithAppSettings = (isFluid, isDark, navbarPosition, showBurgerMenu, navbarStyle) => {
    setConfig(keys.IS_FLUID, isFluid);
    setConfig(keys.IS_DARK, isDark);
    setConfig(keys.NAVBAR_POSITION, navbarPosition);
    setConfig(keys.SHOW_BURGER_MENU, showBurgerMenu);
    setConfig(keys.NAVBAR_STYLE, navbarStyle);
  };

  const checkUserSession = async () => {
    try {
      _isMounted.current && setChecking(true);
      const userProfileRes = await Axios.get(endpoint.userProfile);
      const user = userProfileRes.data;
      console.log('App User Profile: ', user);
      store.dispatch(setCurrentUser(user));
      _isMounted.current && setChecking(false);
      // Disable console.log in prod
      console.log('NODE_ENV:', process.env.NODE_ENV);
    } catch (error) {
      console.log('User needs to login:', error);
      _isMounted.current && setChecking(false);
    }
  };

  const getAppSettings = async () => {
    try {
      _isMounted.current && setLoading(true);
      await sleep(2500); // For splash feel
      const settingsRes = await Axios.get(endpoint.settings);
      const settingsData = settingsRes.data;
      console.log('App Settings: ', settingsData);
      const splash_logo = settingsData?.splash_logoISfile?.uri;
      if (splash_logo) localStorage.setItem(keys.SPLASH_LOGO, splash_logo);

      const config_isDarkYN = settingsData?.config_isDarkYN || false;
      localStorage.setItem(keys.IS_DARK, config_isDarkYN);

      const config_isFluidYN = settingsData?.config_isFluidYN || false;
      localStorage.setItem(keys.IS_FLUID, config_isFluidYN);

      const config_navbarPosition = settingsData?.config_navbarPositionISLIST_top_vertical_combo || null;
      localStorage.setItem(keys.NAVBAR_POSITION, config_navbarPosition);

      const config_showBurgerMenuYN = settingsData?.config_showBurgerMenuYN || false;
      localStorage.setItem(keys.SHOW_BURGER_MENU, config_showBurgerMenuYN);

      const config_navbarStyle = settingsData?.config_navbarStyleISLIST_transparent_vibrant_inverted_card || null;
      localStorage.setItem(keys.NAVBAR_STYLE, config_navbarStyle);

      // Change favicon
      const getFaviconEl = () => {
        return document.getElementById('favicon');
      };
      const favicon = getFaviconEl();
      favicon.href = settingsRes.data.favicon_16ISfile.uri;

      // Change app title
      document.title = settingsData.title;
      store.dispatch(setSettings(settingsData));

      resetConfigWithAppSettings(
        config_isFluidYN,
        config_isDarkYN,
        config_navbarPosition,
        config_showBurgerMenuYN,
        config_navbarStyle
      );

      updateRedirectAfterLoginVal(localStorage.getItem(keys.REDIRECT));

      await checkUserSession(settingsData);

      _isMounted.current && setLoading(false);
    } catch (error) {
      handleError(error, true);
      _isMounted.current && setError('Something went wrong. Please refresh & try again!');
    }
  };

  const updateRedirectAfterLoginVal = val =>
    val !== '/' && val !== '/login' && localStorage.setItem(keys.REDIRECT_AFTER_LOGIN, val);

  useEffect(() => {
    _isMounted.current = true;
    getAppSettings();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  if (loading && error) return <LoadingCenter msg={error} msgColor="tomato" simple={true} />;
  if (loading || checking) return <SplashScreenLoading msg="Loading..." simple={true} />;

  return (
    <>
      <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <AppBody />
        </Router>
      </Provider>
    </>
  );
};

export default App;
