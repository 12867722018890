import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNewMessages: false
};

const routesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    updateMessagesList(state, { payload }) {
      state.isNewMessages = payload;
    }
  }
});

export const { updateMessagesList } = routesSlice.actions;

export default routesSlice.reducer;
