import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentRoute: '',
  isConfirmRoute: true
};

/* Store overall app current(In view) data */
const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    updateCurrentRoute(routes, { payload }) {
      return { ...routes, currentRoute: payload };
    },
    setIsConfirmRoute(routes, { payload }) {
      return { ...routes, isConfirmRoute: payload };
    }
  }
});

export const { updateCurrentRoute, setIsConfirmRoute } = routesSlice.actions;

export default routesSlice.reducer;
